import React from "react"
import { FaChevronCircleRight } from "react-icons/fa"

import Layout from "../components/layout"
import SEO from "../components/seo"

const WhatIsProbate = () => (
  <Layout>
    <SEO title="What is Probate?" />
    <section className="section">
      <div className="container">
        <div className="columns">
          <div className="column is-full is-8-widescreen">
            <h1 className="title is-size-1">What is Probate?</h1>
            <p>
              &#8220;Probate&#8221; refers to the process of distributing your
              assets after you pass away. It is done through the county probate
              court. The court appoints a &#8220;personal representative&#8221;
              (formerly called an &#8220;executor&#8221;) for the estate and
              admits the will, if any. The personal representative must then
              gather the assets, pay the bills, notify creditors, publish notice
              in the newspaper, and distribute the estate to the beneficiaries
              under the will (or heirs if there is no will).
            </p>
            <p>Probate takes a minimum of six months.</p>
            <p>
              Property that is titled only in the decedent&#8217;s name is what
              comprises the probate estate. Other assets do not pass through
              probate. Examples of what does not pass through probate include
              the following:
            </p>
            <ul className="fa-ul content">
              <li>
                <span className="fa-li">
                  <FaChevronCircleRight color="#A42E2D" />
                </span>
                Assets held in trust pass to the trust beneficiaries
              </li>
              <li>
                <span className="fa-li">
                  <FaChevronCircleRight color="#A42E2D" />
                </span>
                Real estate where the owners have a &#8220;Lady-Bird Deed&#8221;
                (see Lady-Bird Deed article for details)
              </li>
              <li>
                <span className="fa-li">
                  <FaChevronCircleRight color="#A42E2D" />
                </span>
                Real estate that is owned as &#8220;Joint Tenants with Right of
                Survivorship&#8221; passes immediately to the survivors upon any
                joint owner’s death
              </li>
              <li>
                <span className="fa-li">
                  <FaChevronCircleRight color="#A42E2D" />
                </span>
                Bank accounts and investments with joint owners pass directly to
                the survivor.
              </li>
              <li>
                <span className="fa-li">
                  <FaChevronCircleRight color="#A42E2D" />
                </span>
                Bank accounts and investments with &#8220;Transfer on
                Death&#8221; (TOD) or &#8220;Pay on Death&#8221; (POD)
                designations pass automatically to the designated beneficiaries
              </li>
              <li>
                <span className="fa-li">
                  <FaChevronCircleRight color="#A42E2D" />
                </span>
                Life insurance, IRAs, 401(k)s, and annuities automatically pass
                to beneficiaries, unless none are named.
              </li>
            </ul>
            <p>
              Many people believe that by having a Will they will avoid probate.
              While a Will may make probate easier, it does not avoid probate. A
              living trust, on the other hand, can avoid probate if the trust is
              properly funded.
            </p>
            <p>
              Rysso Law, PLLC can help with the probate process. We will file
              all necessary forms for you, help gather information, and complete
              the process with a minimum of effort from you.
            </p>
          </div>
        </div>
      </div>
    </section>
  </Layout>
)

export default WhatIsProbate
